import React from "react";
import {
    Link
  } from "react-router-dom";

export const ContactHeader = () => 
<div className="ph-m">
<div className="hours">pon-pt: 8:00 - 20:00</div>
    <div className="ph">
      <Link to="tel:+48516367316">
        <span className="ph-ico"></span>
        516 36 73 16
      </Link>
    </div>
</div>