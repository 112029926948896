import React from "react";
import { NavBarLink } from "./NavBarLink";

  export const HeroNavBar = () => 
    <div className="row icon-tiles">
    <div className="col-xs-4 col-sm-4 icon-tile uslugi">
      <div className="mask">
        <NavBarLink to="uslugi#content">usługi</NavBarLink>
      </div>
    </div>
    <div className="col-xs-4 col-sm-4 icon-tile cennik">
      <div className="mask">
        <NavBarLink to="cennik#content">cennik</NavBarLink>
      </div>
    </div>
    <div className="col-xs-4 col-sm-4 icon-tile form">
      <div className="mask">
        <NavBarLink to="formularz#content">formularz</NavBarLink>
      </div>
    </div>
    </div>