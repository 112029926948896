import React from "react";

export const Formularz = () =>
<div className="text-content">
    <h3>Formularz</h3>
    <form id="form" method="post" name="myemailform" action="form.php">
        <div className="form-group">
            <div className="row">
                <div className="col-md-6">
                    <label htmlFor="name" >Imię i nazwisko</label> <input id="name" className="name_f form-control col-xs-4" type="text" name="name" />
                </div>
                
                <div className="col-md-6">
                    <label htmlFor="contact">Adres e-mail lub telefon</label> <input id="contact" type="text" className="form-control col-xs-4" name="email" />
                </div>
            </div>

            <span className="succes-msg hidden">Wiadomość została wysłana. Odezwiemy się do Ciebie wkrótce po jej przeczytaniu. Dziękujemy!</span>
            <span className="failed-msg hidden">Wiadomość nie została wysłana. Mogą być chwilowe problemy z serwerem, prosimy spróbować pożniej. Przepraszamy</span>
            <label htmlFor="message">Treść</label> <textarea id="message" name="message" className="form-control" rows="4"></textarea>
            <input type="submit" className="btn btn-default" value="wyślij" />
        </div>

    </form>
</div>