import React from "react";
import { Link } from "react-router-dom";

export const Kontakt = () => 

<div className="text-content">
    <div className="kontakt">
        <p>
            <strong>FORDENT Małgorzata Nowacka - Foryś</strong><br/>
            ul. Dulina 1<br/>
            81 - 603 Gdynia<br/>
            NIP: 958-137-71-41
        </p>
        <p>
            <strong>
                Tel. <Link to="tel:+48516367316">516 36 73 16</Link>
            </strong>
        </p>
    </div>

    <div className="mapa">
        <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2316.9826157594584!2d18.436006116034658!3d54.498530195483994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda15ffe688671%3A0x9586be24927231c8!2sFORDENT!5e0!3m2!1spl!2spl!4v1449786804675" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
    </div>
</div>
