import React from "react";

export const Uslugi = () => 

    <div className="text-content">
         <h3>Usługi</h3>
        <div className="paragraph">
            <strong>Profilaktyka</strong> - są to wszystkie czynności i metody mające na celu zapobieganie powstawaniu chorób zębów. Podstawowymi zabiegami profilaktyki są:
        </div>
        <div className="paragraph">

            <ul>
                    <li>
                        <strong>lakowanie</strong> – polega na pokryciu bruzd i zagłębień w zębach lakiem szczelinowym. Bruzdy i szczeliny ze względu na swoją niekorzystną budowę są miejscami, które ograniczają samoczyszczenie oraz oczyszczanie tych miejsc szczoteczką do zębów w trakcie zabiegów higienicznych jamy ustnej. Sprzyja to powstawaniu, najczęściej właśnie w tych miejscach, próchnicy. Dlatego zabieg uszczelniania (czyt. lakowania) powinno się najlepiej przeprowadzać do czwartego miesiąca od wyrżnięcia zęba.
                    </li>
                    <li>
                        <strong>scaling</strong> – usunięcie kamienia nazębnego przy zastosowaniu ultradźwięków
                    </li>
                    <li>
                        <strong>piaskowanie</strong> (często poprzedzone scalingiem) - oczyszczenie zębów z osadów
                        i wygładzanie ich powierzchni przy pomocy strumienia wody wraz ze specjalnymi drobinkami, piaskiem pod ciśnieniem
                    </li>
                    <li>
                        <strong>lakierowanie-fluoryzacja</strong> – polega na pokryciu i wzmocnieniu powierzchni zębów specjalnym preparatem o wysokim stężeniu fluoru, aby wzmocnić zewnętrzną warstwę zęba czyli szkliwo. Wzmocnienie głównego budulca szkliwa jonami fluoru zwiększa odporność zęba na działanie kwasów, a jednocześnie hamuje rozwój bakterii próchnicotwórczych.
                    </li>
            </ul>

        </div>
        <div className="paragraph">
            <strong>Stomatologia zachowawcza</strong> – do czynności tej dziedziny stomatologii zaliczamy:
            <ul>
                    <li>oczyszczanie ubytku próchniczego</li>
                    <li>zatrzymanie rozwoju i zmniejszenie dynamiki procesu próchnicowego</li>
                    <li>odtworzenie kształtu zęba</li>
            </ul>
            Efektem leczenie zachowawczego jest wykonanie wypełnienia (plomby) w zębie.
        </div>

        <div className="paragraph">
            <strong>Stomatologia dziecięca</strong> (pedodoncja) – to specjalizacja stomatologiczna wyodrębniona z powodu odrębności budowy zębów i całego układu stomatologicznego w okresie rozwoju. Pedodonacja zajmuje się między innymi:
            <ul>
                    <li><strong>ząbkowaniem</strong></li>
                    <li>leczeniem zębów mlecznych</li>
                    <li>leczeniem zębów stałych niedojrzałych</li>
                    <li>leczeniem chorób błony śluzowej u dzieci</li>
                    <li><strong>profilaktyką próchnicy</strong>, błony śluzowej i wad zgryzu u dzieci</li>
            </ul>
        </div>

        <div className="paragraph">
            <strong>Endodoncja</strong> – inaczej  <strong>leczenie kanałowe</strong>, polegające na usunięciu zainfekowanej miazgi z komory i kanałów korzeniowych zęba poprzez mechaniczne i chemiczne opracowanie, a następnie wypełnienie ich specjalnym, biokompatybilnym materiałem.
        </div>

        <div className="paragraph">
            <strong>Protetyka</strong> – zajmuje się:
            <ul>
                    <li>odtworzeniem braków zębowych</li>
                    <li>odbudową złamanych lub bardzo zniszczonych zębów</li>
                    <li><strong>Również korektą kształtu i usunięciem przebarwień zębów</strong></li>
            </ul>
            W zależności od sytuacji wykonuje się:
            <ul>
                    <li><strong>protezy ruchome</strong> – do których należą:</li>
                    <li><strong>protezy szkieletowe</strong></li>
                    <li><strong>protezy osiadające całkowite</strong></li>
                    <li><strong>protezy osiadające częściowe</strong></li>
                    <li><strong>protezy stałe, do których należą:</strong></li>
                    <li><strong>wkłady koronowo-korzeniowe</strong></li>
                    <li><strong>korony protetyczne</strong></li>
                    <li><strong>mosty</strong></li>
            </ul>
        </div>
        <div className="paragraph">
            <strong>Stomatologia estetyczna</strong> – głównym celem jest poprawienie wyglądu estetycznego uzębienia poprzez  <strong>licówki kosmetyczne</strong> bądź  <strong>wybielanie</strong>. Do wyboru mamy dwie metody wybielania:
            <ul>
                    <li>
                        <strong>metodę domową</strong> – tzw. wybielanie nakładkowe. Pacjent aplikuje żel na specjalnie przygotowaną wcześniej nakładkę i zakłada ją na noc. Efekt jest widoczny po kilku dniach.
                    </li>
                    <li>
                        <strong>metoda gabinetowa</strong> – lekarz stosuje znacznie mocniejszy środek i dzięki temu uzyskuje efekt od razu.
                    </li>
            </ul>
        </div>

        <div className="paragraph">
            <strong>Periodontologia</strong> – zajmuje się chorobami dziąseł i przyzębia. Oprócz leczenia farmakologicznego stosuje się <strong>scaling i piaskowanie</strong> (czyt. profilaktyka). Kamień nazębny odkładając się na powierzchni zębów. W pobliżu dziąseł niszczy je mechanicznie, a bakterie bytujące w płytce tego kamienia tworzą proces zapalny dziąsła, następnie z czasem zapalenie przyzębia w konsekwencji doprowadzając do zaniku kości.
        </div>
    </div>
