import { Provider } from "../Context";
import CennikList from "../components/CennikList";
import { Actions } from "../Actions";

export const Cennik = () => {
  const data = Actions();
  return (
    <Provider value={data}>
      <div className="App">
        <div className="wrapper">
          <section className="right-side">
            <CennikList />
          </section>
        </div>
      </div>
    </Provider>
  );
}

export default Cennik;