import { useState } from "react";
import { Provider } from "../Context";
import Form from "../components/Form";
import CennikEditList from "../components/CennikEditList";
import { Actions } from "../Actions";

export const CennikEdit = () => {
  const data = Actions();
  const [currentTab, setCurrentTab] = useState('stomatologia');
  const setTab = (selectedTab) => {
    setCurrentTab(selectedTab)
  }
  return (
    <Provider value={data}>
      <div className="App">
        <div className="wrapper">
          <section className="left-side">
            <Form currentTab={currentTab} />
          </section>
          <section className="right-side">
            <CennikEditList selectedTab={setTab} />
          </section>
        </div>
      </div>
    </Provider>
  );
}

export default CennikEdit;