import React from "react";

export const Dzieci = () => 

<div className="text-content">
    <h3>Dzieci</h3>
  <p>
    Serdecznie zapraszamy naszych najmłodszych pacjentów na wizytę adaptacyjną
    i leczenie. Warto odwiedzić gabinet z dzieckiem przed pierwszym leczeniem, w celu budowania wspólnego zaufania, zmniejszenia obaw przed wizytą i wykształcenia pozytywnego nastawienia ‘małego’ pacjenta do stomatologa.
  </p>
</div>