import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

export const Footer = () =>
<footer className="footer">
        <div className="container">
          <div className="links col-md-3 col-sm-3 col-xs-5">
                <strong>FORDENT</strong><br/>
                  516 36 73 16<br/>
                  pon, śrd-pt 10:00 - 16:00<br/>
                  wt 15:00 - 20:00<br/>
                  gabinet@fordent.pl
          </div>
          <div className="links col-md-3 col-sm-3 col-xs-5">  
                  <strong>adres</strong><br/>
                  ul. Dulina 1<br/>
                  81 - 603 Gdynia<br/>   
          </div>
          <div className="links col-md-3 col-sm-3 col-xs-5">
            <span className="xs-footer-label"><strong>linki</strong></span>
              <ul className="link-list">
                  <li><Link smooth to="/">gabinet</Link></li>
                  <li><Link smooth to="zespol#content">zespół</Link></li>
                  <li><Link smooth to="stomatologia#content">stomatologia</Link></li>
                  <li><Link smooth to="medycyna_estetyczna#content">medycyna estetyczna</Link></li>
                  <li><Link smooth to="cennik#content">cennik</Link></li>
              </ul>
          </div>
          <div className="links col-md-3 col-sm-3 col-xs-5">
              <ul className="link-list">
                  <li><Link smooth to="dzieci#content">dzieci</Link></li>
                  <li><Link smooth to="uslugi#content">uslugi</Link></li>
                  <li><Link smooth to="kontakt#content">kontakt</Link></li>
                  <li><Link smooth to="formularz#content">formularz</Link></li>
              </ul>
          </div>
        </div>
    </footer>