import React from "react";

export const Gabinet = () => 

<div className="text-content">
  <p>Jest nam bardzo miło, że zajrzeli Państwo na naszą stronę.</p>
  <p>
  Praktyka stomatologiczna została otwarta we wrześniu 2008r. Zapraszamy dzieci i dorosłych na komfortowe leczenie w miłej i bezstresowej atmosferze. Gabinet jest dostosowany do potrzeb osób niepełnosprawnych i posiada nowoczesny sprzęt do sterylizacji narzędzi. Stosujemy sprawdzone materiały wysokiej jakości oraz nowoczesny sprzęt do leczenie kanałowego (m.in. mikroskop oraz endometr).
  </p>
  <p>
  Dla Państwa wygody zapewniamy kompleksowe leczenie na wysokim poziomie, począwszy od profilaktyki poprzez leczenie chorób przyzębia i zębów, kończąc na implantoprotetyce oraz ortodoncji tym samym poprawiając estetykę.
  </p>

  <p>
    Dla komfortu Naszych pacjentów, zarówno tych małych, jak i większych, wyposażyliśmy
    gabinet w urządzenie z podtlenkiem azotu, czyli tzw. „gazem rozweselającym”
  </p>

  <p>
  Gabinet jest w pełni klimatyzowany, posiadamy również cztero stanowiskowy parking dla naszych klientów.
  </p>
  <p>
  <strong>Istotą naszej pracy jest satysfakcja pacjentów i ich zadowolenie z leczenia.</strong> Zabieganym i nie przygotowanym do wizyty pacjentom proponujemy skorzystanie z jednorazowych szczoteczek nasączonych pastą.
  </p>
</div>