import { useContext, useState, useCallback, memo } from "react";
import { AppContext } from "../Context";

const CennikEditList = memo(({selectedTab}) => {
  const {
    users,
    cennikMe,
    userLength,
    editMode,
    cancelEdit,
    updateUser,
    deleteUser,
    selectedTabValue,
  } = useContext(AppContext);

  const [currentTab, setCurrentTab] = useState('stomatologia');

  // Storing users new data when they editing their info.
  const [newData, setNewData] = useState({
    id: null,
    typ: 'usluga',
    usluga: '',
    cena: '',
  });

  const saveBtn = useCallback(() => {
    updateUser(newData, selectedTabValue);
  }, [newData, updateUser, selectedTabValue]);

  const updateNewData = (e, field) => {
    setNewData({
      ...newData,
      [field]: e.target.value,
    });
  };

  const enableEdit = useCallback((id, typ, usluga, cena) => {
    setNewData({ id, typ, usluga, cena });
    editMode(id, currentTab);
  }, [editMode, currentTab]);

  const deleteConfirm = (id) => {
    if (window.confirm("Czy na pewno chcesz usunąć ten element?")) {
      deleteUser(id, currentTab);
    }
  }; 

  const setTab = useCallback((e) => {
    setCurrentTab(e.target.dataset.tab);
    selectedTab(e.target.dataset.tab);
  }, [selectedTab])

  return !userLength ? (
    <p>{userLength === null ? "Ładowanie..." : "Dodaj usługi"}</p>
  ) : (
    <div className="text-content">
        <div className="radio-tabs" onChange={ e => {setTab(e)}}>
          <input className="state" value="stomatologia" data-tab="stomatologia" type="radio" title="stomatologia" name="tabs" id="stomatologia" defaultChecked />
          <input className="state" value="medycyna" data-tab="medycyna" type="radio" title="medycyna" name="tabs" id="medycyna" />  

          <div className="tabs">
              <label htmlFor="stomatologia" id="stomatologia-tab" className="tab">stomatologia</label>
              <label htmlFor="medycyna" id="medycyna-tab" className="tab">medycyna estetyczna</label>	      
          </div>


              <div className="panels">
                <div id="stomatologia-panel" className="panel active">
                    <div className="text-content">	

                      <table width="100%">
                        <thead>
                          <tr>
                            <th>Usługa</th>
                            <th>Cena</th>
                            <th width="20%">Opcje</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map(({ id, typ, usluga, cena, isEditing }) => {
                            return isEditing === true ? (
                              <tr key={id}>
                                <td style={{color: '#656565'}} className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  <select defaultValue="usluga" name="_typ" id="_typ" onChange={(e) => updateNewData(e, "typ")}>
                                    <option value="kategoria">kategoria</option>
                                    <option value="usluga">usługa</option>
                                  </select>
                                </td>
                                <td style={{color: '#656565'}} className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  <input
                                    type="text"
                                    defaultValue={usluga}
                                    onChange={(e) => updateNewData(e, "usluga")}
                                  />
                                </td>
                                <td className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  {newData.typ === 'usluga' && <input
                                    type="email"
                                    defaultValue={cena}
                                    onChange={(e) => updateNewData(e, "cena")}
                                  />}
                                </td>
                                <td>
                                  <button className="btn green-btn" onClick={(e, currentTab) => {saveBtn(currentTab)}}>
                                    Zapisz
                                  </button>
                                  <button
                                    className="btn default-btn"
                                    onClick={() => cancelEdit(id)}
                                  >
                                    Anuluj
                                  </button>
                                </td>
                              </tr>
                            ) : (
                              <tr key={id}>
                                <td className={typ === 'kategoria' ? 'rodzaj' : 'usluga'} colSpan={typ === 'kategoria' ? 2 : 0}>{usluga}</td>
                                {typ === 'usluga' && <td className="cena">{cena}</td>}
                                <td>
                                  <button
                                    className="btn default-btn"
                                    onClick={() => {enableEdit(id, typ, usluga, cena)}}
                                  >
                                    Edycja
                                  </button>
                                  <button
                                    className="btn red-btn"
                                    onClick={() => deleteConfirm(id)}
                                  >
                                    Usuń
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'center'}}>
                              <b>Przedstawione ceny mają charakter orientacyjny</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                  </div>
                </div>
              </div>


              <div className="panels">
                <div id="medycyna-panel" className="panel active">
                    <div className="text-content">	

                      <table width="100%">
                        <thead>
                          <tr>
                            <th>Usługa</th>
                            <th>Cena</th>
                            <th width="20%">Opcje</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cennikMe.map(({ id, typ, usluga, cena, isEditing }) => {
                            return isEditing === true ? (
                              <tr key={id}>
                                <td style={{color: '#656565'}} className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  <select defaultValue="usluga" name="_typ" id="_typ" onChange={(e) => updateNewData(e, "typ")}>
                                    <option value="kategoria">kategoria</option>
                                    <option value="usluga">usługa</option>
                                  </select>
                                </td>
                                <td style={{color: '#656565'}} className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  <input
                                    type="text"
                                    defaultValue={usluga}
                                    onChange={(e) => updateNewData(e, "usluga")}
                                  />
                                </td>
                                <td className={newData.typ === 'kategoria' ? 'rodzaj' : 'usluga'}>
                                  {newData.typ === 'usluga' && <input
                                    type="email"
                                    defaultValue={cena}
                                    onChange={(e) => updateNewData(e, "cena")}
                                  />}
                                </td>
                                <td>
                                  <button className="btn green-btn" onClick={() => saveBtn(currentTab)}>
                                    Save
                                  </button>
                                  <button
                                    className="btn default-btn"
                                    onClick={() => cancelEdit(id)}
                                  >
                                    Cancel
                                  </button>
                                </td>
                              </tr>
                            ) : (
                              <tr key={id}>
                                <td className={typ === 'kategoria' ? 'rodzaj' : 'usluga'} colSpan={typ === 'kategoria' ? 2 : 0}>{usluga}</td>
                                {typ === 'usluga' && <td className="cena">{cena}</td>}
                                <td>
                                  <button
                                    className="btn default-btn"
                                    onClick={() => enableEdit(id, typ, usluga, cena)}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="btn red-btn"
                                    onClick={() => deleteConfirm(id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'center'}}>
                              <b>Przedstawione ceny mają charakter orientacyjny</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                  </div>
                </div>
              </div>

              
      </div>
    </div>
  );
});

export default CennikEditList;