import React from "react";
import {
    useResolvedPath,
    useMatch,
  } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
  
export const NavBarLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    
    return (
          <Link
            className={match ? 'active' : ''}
            to={to}
            smooth
            {...props}
          >
            {children}
          </Link>
      );
  };