import React from "react";
import { Link } from "react-router-dom";
import { NavBarLink } from "./NavBarLink";

export const NavBar = () => 
    <div className="navbar navbar-default navbar-static-top head">
        <div className="ph-h col-xs-4 col-sm-4 col-md-3">
            <div className="hours">
                pon-pt: <strong>8:00 - 20:00 </strong>                     
            </div>
            <div className="ph">
                <span className="ph-ico"></span>
                516 36 73 16
            </div>
        </div>
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
        </button>
        <div className="navbar-header logo">
            <Link to="/" className="navbar-brand"></Link>
        </div>
        <div className="row navbar-collapse collapse menu">
            <ul className="nav navbar-nav">
                <li><NavBarLink to="/">gabinet</NavBarLink></li>
                <li><NavBarLink to="zespol#content">zespół</NavBarLink></li>
                <li><NavBarLink to="stomatologia#content">stomatologia</NavBarLink></li>
                <li><NavBarLink to="medycyna_estetyczna#content">medycyna estetyczna</NavBarLink></li>
                <li><NavBarLink to="dzieci#content">dzieci</NavBarLink></li>
                <li><NavBarLink to="kontakt#content">kontakt</NavBarLink></li>
            </ul>
        </div>
    </div>