import { useState, useContext } from "react";
import { AppContext } from "../Context";
const Form = ({currentTab}) => {
  const { insertUser, insertMeItem } = useContext(AppContext);
  const [newUser, setNewUser] = useState({
    typ: 'usluga',
    usluga: '',
    cena: '',
  });

  // Storing the Insert User Form Data.
  const addNewUser = (e, field) => {
    setNewUser({
      ...newUser,
      [field]: e.target.value,
    });
  };

  // Inserting a new user into the Database.
  const submitUser = (e) => {
    e.preventDefault();
    currentTab === 'medycyna' ? insertMeItem(newUser) : insertUser(newUser)

    e.target.reset();
    setNewUser({
      typ: 'usluga',
      usluga: '',
      cena: '',
    })
  };

  return (
    <form className="insertForm" onSubmit={submitUser}>
            <label htmlFor="_typ">Typ</label>
            <select defaultValue="usluga" name="_typ" id="_typ" onChange={(e) => addNewUser(e, "typ")}>
              <option value="kategoria">kategoria</option>
              <option value="usluga">usługa</option>
            </select>
      <label htmlFor="_usluga">Usługa</label>
      <input
        type="text"
        id="_usluga"
        onChange={(e) => addNewUser(e, "usluga")}
        placeholder="Wpisz nazwę usługi"
        autoComplete="off"
        required
      />
      <label htmlFor="_cena">Cena</label>
      { newUser.typ === 'usluga' && <input
        type="text"
        id="_cena"
        onChange={(e) => addNewUser(e, "cena")}
        placeholder="Wpisz cenę"
        autoComplete="off"
        required={newUser.typ === 'usluga'}
      />}
      <input type="submit" value="Dodaj" />
    </form>
  );
};

export default Form;