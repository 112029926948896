import {
  Routes,
  Route,
} from "react-router-dom";
import { ContactHeader } from "./components/ContactHeader";
import { Footer } from "./components/Footer";
import { HeroHeader } from "./components/HeroHeader";
import { HeroNavBar } from "./components/HeroNavBar";
import { NavBar } from "./components/NavBar";
import { Cennik } from "./pages/Cennik";
import { CennikEdit } from "./pages/CennikEdit";
import { Dzieci } from "./pages/Dzieci";
import { Formularz } from "./pages/Formularz";
import { Gabinet } from "./pages/Gabinet";
import { Kontakt } from "./pages/Kontakt";
import { MedEstetyczna } from "./pages/MedEstetyczna";
import { Stomatologia } from "./pages/Stomatologia";
import { Uslugi } from "./pages/Uslugi";
import { Zespol } from "./pages/Zespol";

const App = () => 
<div className="App">
  <div className="wrapper">
      <header>
        <div className="container">
          <NavBar />
        </div>
        <ContactHeader />
      </header>
    </div>
    <section>
          <div className="container">
            <HeroHeader />
            <HeroNavBar />
            </div>
    </section>
    <section className="content">
          <div className="container">
            <div id="content" className="content main">
          <Routes>
            <Route exact path="/" element={<Gabinet />}/>
            <Route exact path="/zespol" element={<Zespol />}/>
            <Route exact path="/stomatologia" element={<Stomatologia />}/>
            <Route exact path="/medycyna_estetyczna" element={<MedEstetyczna />}/>
            <Route exact path="/dzieci" element={<Dzieci />}/>
            <Route exact path="/kontakt" element={<Kontakt />}/>
            <Route exact path="/uslugi" element={<Uslugi />}/>
            <Route exact path="/cennik" element={<Cennik />}/>
            <Route exact path="/cennikEdycja" element={<CennikEdit />}/>
            <Route exact path="/formularz" element={<Formularz />}/>
          </Routes>
        </div>
          </div>
    </section>
    <Footer />
</div>

export default App;
