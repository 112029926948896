import { useContext } from "react";
import { AppContext } from "../Context";

const CennikList = () => {
  const {
    users,
    cennikMe,
  } = useContext(AppContext);

  return (
    <div className="text-content">
        <h3>Cennik</h3>
        <div className="radio-tabs">
            <input className="state" type="radio" title="stomatologia" name="houses-state" id="stomatologia" defaultChecked />
            <input className="state" type="radio" title="Lanisters" name="houses-state" id="medycyna" />  

            <div className="tabs">
                <label htmlFor="stomatologia" id="stomatologia-tab" className="tab">stomatologia</label>
                <label htmlFor="medycyna" id="medycyna-tab" className="tab">medycyna estetyczna</label>	      
            </div>

            <div className="panels">

                <div id="stomatologia-panel" className="panel active">
                    <div className="text-content">	
                        <table id="tabela_cen" border="0" width="100%">	
            
                            <tbody>
                                {users.map(({ id, typ, usluga, cena }) => 
                                    <tr key={id}>
                                    <td className={typ === 'kategoria' ? 'rodzaj' : 'usluga'} colSpan={typ === 'kategoria' ? 2 : 0}>{usluga}</td>
                                    {typ === 'usluga' && <td className="cena">{cena}</td>}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center'}}>
                                    <b>Przedstawione ceny mają charakter orientacyjny</b>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div id="medycyna-panel" className="panel">
                    <div className="text-content">	
                        <table id="tabela_cen" border="0" width="100%">	
                            <tbody>
                                {cennikMe.map(({ id, typ, usluga, cena }) => 
                                    <tr key={id}>
                                    <td className={typ === 'kategoria' ? 'rodzaj' : 'usluga'} colSpan={typ === 'kategoria' ? 2 : 0}>{usluga}</td>
                                    {typ === 'usluga' && <td className="cena">{cena}</td>}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center'}}>
                                    <b>Przedstawione ceny mają charakter orientacyjny</b>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div> 

            </div>
        </div>
    </div>
  );
};

export default CennikList; 