import { useEffect, useState } from "react";

export const Actions = () => {
  let [users, setUsers] = useState([]);
  let [cennikMe, setcennikMe] = useState([]);
  let [selectedTabValue, setSelectedTab] = useState('stomatologia');

    //userLength is for showing the Data Loading message.
  let [userLength, setUserLength] = useState(null);
  let [cennikMeLength, setcennikMeLength] = useState(null);

  useEffect(() => {
    fetch("/all-users.php")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setUsers(data.cennik);
          setUserLength(true);
        } else {
          setUserLength(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetch("/cennik-medest.php")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setcennikMe(data.medest);
          setcennikMeLength(true);
        } else {
          setcennikMeLength(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Inserting a new user into the database.
  const insertUser = (newUser) => {
    fetch("/add-user.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUser),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.id) {
          setUsers([
            ...users,
            {
              id: data.id,
              ...newUser,
            },
          ]);
          setUserLength(true);
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const insertMeItem = (newMeItem) => {
    fetch("/add-me-item.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newMeItem),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.id) {
          setcennikMe([
            ...cennikMe,
            {
              id: data.id,
              ...newMeItem,
            },
          ]);
          setcennikMeLength(true);
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Enabling the edit mode for a listed user.
  const editMode = (id, currentTab) => {
    setSelectedTab(currentTab);
    if(currentTab === 'stomatologia') {
      users = users.map((user) => {
        if (user.id === id) {
          user.isEditing = true;
          return user;
        }
        user.isEditing = false;
        return user;
      });
      setUsers(users);
  } else {
    cennikMe = cennikMe.map((item) => {
        if (item.id === id) {
          item.isEditing = true;
          return item;
        }
        item.isEditing = false;
        return item;
      });
      setUsers(cennikMe);
    }
  };

  // Cance the edit mode.
  const cancelEdit = (id) => {
    users = users.map((user) => {
      if (user.id === id) {
        user.isEditing = false;
        return user;
      }
      return user;
    });
    setUsers(users);
  };

  // Updating a user.
  const updateUser = (userData, currentTab) => {

    const updateUrl = currentTab === 'stomatologia' ? 
    '/update-user.php' : '/update-me-item.php';

    let dataSource = currentTab === 'stomatologia' ? users : cennikMe;

    fetch(updateUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          dataSource = dataSource.map((user) => {
            if (user.id === userData.id) {
              user.isEditing = false;
              user.usluga = userData.usluga;
              user.cena = userData.cena;
              return user;
            }
            return user;
          });
          currentTab === 'stomatologia' ?  setUsers(dataSource) : setcennikMe(dataSource);
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Deleting a user.
  const deleteUser = (theID, currentTab) => {
    const deleteUrl = currentTab === 'stomatologia' ? 
    '/delete-user.php' : '/delete-me-item.php';
      // filter outing the user.
    let userDeleted = users.filter((user) => {
      return user.id !== theID;
    });
    let cennikMeItemDeleted = cennikMe.filter((item) => {
      return item.id !== theID;
    });
    fetch(deleteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: theID }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          currentTab === 'stomatologia' ? setUsers(userDeleted) : setcennikMe(cennikMeItemDeleted);
          if (users.length === 1) {
            setUserLength(0);
          }
          if (cennikMe.length === 1) {
            setcennikMeLength(0);
          }
        } else {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    users,
    cennikMe,
    editMode,
    cancelEdit,
    updateUser,
    insertUser,
    insertMeItem,
    deleteUser,
    userLength,
    cennikMeLength,
    selectedTabValue,
  };
};