import React from "react";

export const Zespol = () =>
<div className="text-content">
<h3>Nasz Zespół</h3>
<div className="radio-tabs">
    <input className="state" type="radio" title="lekarze" name="houses-state" id="lekarze" defaultChecked />
    <input className="state" type="radio" title="personel" name="houses-state" id="personel" />  
    <div className="tabs">
        <label htmlFor="lekarze" id="lekarze-tab" className="tab">lekarze</label>
        <label htmlFor="personel" id="personel-tab" className="tab">personel</label>	      
    </div>
    <div className="panels">
        <div id="lekarze-panel" className="panel active">
            <div className="flex-grid-wrapper team">
                <div className="flex-grid-item">
                    <img alt="" src="../img/Malgorzata-Nowacka-Forys_m.jpg" />		
                    <span className="name">MAŁGORZATA NOWACKA - FORYŚ</span>
                    <span className="prof">lekarz stomatolog</span>
                </div>	
                <div className="flex-grid-item">
                    <img alt="" src="../img/avatar.jpg" />
                    <span className="name">PIOTR LENARCZYK</span>
                    <span className="prof">lekarz dentysta</span>
                </div>
                <div className="flex-grid-item">
                    <img alt="" src="../img/Maria-Harasimiuk_m.jpg" />
                    <span className="name">MARIA HARASIMIUK</span>
                    <span className="prof">lekarz dentysta</span>
                </div>
            </div>
        </div>
        <div id="personel-panel" className="panel">
            <div className="flex-grid-wrapper team">
                <div className="flex-grid-item">
                    <img alt="" src="../img/Danuta-Wojna_m.jpg" />
                    <span className="name">DANUTA WOJNA</span>
                    <span className="prof">Asystentka stomatologiczna</span>
                </div>
                <div className="flex-grid-item">
                    <img alt="" src="../img/avatar.jpg" />
                    <span className="name">KAROLINA NOWACKA-CZECHOLIŃSKA</span>
                    <span className="prof">Higienistka</span>
                </div>
                <div className="flex-grid-item">
                    <img alt="" src="../img/avatar.jpg" />
                    <span className="name">JOANNA RUTKOWSKA</span>
                    <span className="prof">Asystentka stomatologiczna</span>
                </div>	        
            </div>
        </div>    
    </div>
</div>
</div>